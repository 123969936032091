import { Injectable, Signal, signal } from '@angular/core';

export type ToastType = 'primary'| 'success' | 'danger' | 'info' | 'warning';
export type ToastPlacement = 'top-start' | 'top-center' | 'top-end'; //| 'bottom-start' | 'bottom-center' | 'bottom-end';
export interface Toast {
  message: string;
  title?: string;
  type: ToastType;
  delay?: number;
  placement?: ToastPlacement;
}

@Injectable({
  providedIn: 'root'
})
export class ToasterService {
  private toast= signal<Toast>({} as Toast);

  toastState: Signal<Toast> = this.toast as Signal<Toast>;

  private show(message: string, type: ToastType, title?: string, delay: number = 5000, placement: ToastPlacement = 'top-end') {
    this.toast.set({ title, message, type, delay, placement });
  }
  showPrimary(message: string, delay: number = 5000, placement: ToastPlacement = 'top-end', title?: string) {
    this.show(message, 'primary', title, delay, placement);
  }
  showSuccess(message: string, delay: number = 5000, placement: ToastPlacement = 'top-end', title?: string) {
    this.show(message, 'success', title, delay, placement);
  }

  showError(message: string, delay: number = 5000, placement: ToastPlacement = 'top-end', title?: string) {
    this.show(message, 'danger', title, delay, placement);
  }

  showInfo(message: string, delay: number = 5000, placement: ToastPlacement = 'top-end', title?: string) {
    this.show(message, 'info', title, delay, placement);
  }

  showWarning(message: string, delay: number = 5000, placement: ToastPlacement = 'top-end', title?: string) {
    this.show(message, 'warning', title, delay, placement);
  }
}
